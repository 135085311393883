import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const studyFootnotes = [
    {
        label: 'f.',
        text: 'As defined per AJCC 7th ed.'
    },
    {
        label: 'g.',
        text: 'Randomization was stratified by stage (IB vs II vs IIIA), recipient of adjuvant chemotherapy (yes vs no), PD&#8288;-&#8288;L1 status (TPS <1% [negative] vs TPS 1%–49% vs TPS ≥50%), and geographic region (Western Europe vs Eastern Europe vs Asia vs rest of world).'
    },
    {
        label: 'h.',
        text: 'RECIST v1.1–defined disease recurrence as determined by the investigator.'
    },
    {
        label: 'i.',
        text: 'Investigator-assessed DFS was defined as the time between the date of randomization and the date of first recurrence (local/regional recurrence, distant metastasis), appearance of a second NSCLC primary or other malignancy, or death from any cause, whichever occurred first.<sup>1</sup>'
    },
]

const studyDefinitions = 'AJCC = American Joint Committee on Cancer; ECOG PS = Eastern Cooperative Oncology Group performance status; Q3W = every 3 weeks; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; TPS = tumor proportion score.'

const NSCLC_ADJUVANT = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview
            title="Study Overview"
            description="KEYNOTE-091: A multicenter, randomized, triple-blind, placebo-controlled phase 3 trial in patients with completely resected stage IB (T2a ≥4 cm), II, or IIIA NSCLC<sup>1,f</sup>"
            data={[
                [
                    {
                        title: ' ',
                        description: `
                            <p>Key Inclusion Criteria</p>
                            <ul>
                                <li>Completely resected stage IB (T2a ≥4 cm), II, or IIIA NSCLC per AJCC 7th ed, regardless of tumor PD-L1 expression</li>
                                <li>No prior neoadjuvant radiotherapy and/or neoadjuvant chemotherapy</li>
                                <li>No prior adjuvant radiotherapy for the current malignancy<sup>1</sup></li>
                                <li>May or may not have received adjuvant chemotherapy (up to 4 cycles)</li>
                            </ul>
                            <p>Key Exclusion Criteria</p>
                            <ul>
                                <li>Active autoimmune disease</li>
                                <li>Use of chronic immunosuppressive agents</li>
                                <li>History of interstitial lung disease or pneumonitis</li>
                            </ul>
                        `,
                        type: 'box-gradient',
                        colspan: 2
                    }
                ],
                [
                    {
                        type: 'arrow-gray',
                        colspan: 2
                    }
                ],
                [
                    {
                        title: 'Randomized<sup>g</sup> 1:1 (N=1,177)',
                        colspan: 2
                    }
                ],
            ]}
        />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA<sup>1</sup>",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 200&nbsp;mg IV Q3W (n=590)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Treatment continued until disease recurrence,<sup>h</sup> unacceptable toxicity, or up to 1 year (18 doses)<sup>1</sup>`
                        }
                    ]
                ]
            },
            {
                title: "Placebo<sup>1</sup>",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Placebo IV Q3W (n=587)`
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Treatment continued until disease recurrence,<sup>h</sup> unacceptable toxicity, or up to 1 year (18 doses)<sup>1</sup>`
                        }
                    ]
                ]
            }
        ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <ul>
                    <li><b>Major efficacy outcome measure:</b> DFS<sup>i</sup> in the overall population.<sup>1</sup></li>
                    <li><strong>Additional efficacy outcome measure:</strong> OS in the overall population.<sup>1</sup></li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            title="Patient Baseline Characteristics"
            description={`
                <p>Of 1,177 patients randomized, 1,010 (86%) received adjuvant platinum-based chemotherapy following resection. The baseline characteristics of patients who received adjuvant platinum-based chemotherapy (n=1,010) were:</p>
                <ul>
                    <li>Median age: 64 years (range: 35–84)</li>
                    <li>Age 65 or older: 49%</li>
                    <li>Male: 68%</li>
                    <li>Ethnicity/race
                        <ul>
                            <li>White: 77%</li>
                            <li>Asian: 18%</li>
                        </ul>
                    </li>
                    <li>Geographic region
                        <ul>
                            <li>Western Europe: 52%</li>
                            <li>Eastern Europe: 20%</li>
                            <li>Asia: 17%</li>
                            <li>Rest of world: 11%</li>
                        </ul>
                    </li>
                    <li>Smoking status
                        <ul>
                            <li>Current or former smoker: 86%</li>
                        </ul>
                    </li>
                    <li>ECOG PS
                        <ul>
                            <li>1: 39%</li>
                        </ul>
                    </li>
                    <li>Stage of disease at diagnosis<sup>f</sup>
                        <ul>
                            <li>Stage IB (T2a ≥4 cm): 11%</li>
                            <li>Stage II: 57%</li>
                            <li>Stage IIIA: 31%</li>
                        </ul>
                    </li>
                    <li>PD&#8288;-&#8288;L1 expression
                        <ul>
                            <li>TPS <1%: 39%</li>
                            <li>TPS 1%–49%: 33%</li>
                            <li>TPS ≥50%: 28%</li>
                        </ul>
                    </li>
                </ul>
            `} 
            footnotes={studyFootnotes}
            definitions={studyDefinitions}
        />
 
    </ComponentWrapper>
);

export default NSCLC_ADJUVANT;
