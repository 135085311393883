import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, FeaturedStat, KMCurve, SecondaryCallout, PatientProfile, ReferencesBlock, ComparisonTable } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import NSCLC_ADJUVANT from '../../components/StudyDesign/__study-design/nsclc-adjuvant'
import { jobCodes } from '../../util/data/jobCodes';

const pageMeta = {
    title: `KEYNOTE-091 - Clinical Trial Results | HCP`,
    keywords: `keynote-091`,
    description: `Health care professionals may find clinical trial results for adjuvant therapy for stage 1B (T2a ≥4 cm), stage 2, or stage 3A NSCLC in KEYNOTE-091.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-adjuvant-therapy/","@type":"ApprovedIndication","name":" KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, as a single agent, is indicated for adjuvant treatment following resection and platinum-based chemotherapy for adult patients with stage IB (T2a ≥4 cm), II, or IIIA non–small cell lung cancer (NSCLC). "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-adjuvant-therapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-adjuvant-therapy/","@type":"MedicalTrial","name":"Clinical Findings From KEYNOTE⁠-⁠091","description":"Study Design for KEYNOTE⁠-⁠091","trialDesign":"KEYNOTE-091: A multicenter, randomized, triple-blind, placebo-controlled phase 3 trial in patients with completely resected stage IB (T2a ≥4 cm), II, or IIIA NSCLC"}`
    ]
};

const indicationId = 33;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Nonsquamous', url: '/efficacy/nsclc-nonsquamous-first-line-combination-therapy' },
                { text: '1L Combo Therapy – Squamous', url: '/efficacy/nsclc-squamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-first-line-monotherapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/efficacy/nsclc-perioperative-regimen/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-adjuvant-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1-expression-testing/' }
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;091' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;091' },
    { label: 'Appropriate Patients' },
];

const kmFootnotes = [
    {
        label: "a.",
        text: 'HR based on the unstratified univariate Cox regression model.'
    }
];

const featuredStatFootnotes = [
    {
        label: "b.",
        text: 'HR based on the unstratified univariate Cox regression model.'
    }
];

const comparisonTableFootnotes = [
    {
        label: "c.",
        text: 'HR based on the unstratified univariate Cox regression model.'
    }
];

const secondaryCalloutFootnotes = [
    {
        label: "d.",
        text: '4.891 years.'
    },
    {
        label: "e.",
        text: '2.908 years.'
    },
];

const patientProfileSandra = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-sandra.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Sandra",
    diagnosis: "Stage IB<sup>j</sup> and T2a&nbsp;≥4&nbsp;cm, N0, M0 nonsquamous NSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'Has undergone resection and received adjuvant platinum-based chemotherapy',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: 'TPS 65%',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '58',
        },
        {
            key: 'Gender',
            value: 'Female',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking Status',
            value: 'Former smoker, 14 pack-year history, quit 2 years ago',
        },
    ],
};

const patientProfileKevin = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-kevin.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Kevin",
    diagnosis: "Stage IIA<sup>j</sup> and T2b, N0, M0 squamous NSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'Has undergone a lobectomy and received adjuvant platinum-based chemotherapy',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '0',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: 'TPS 20%',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '63',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking Status',
            value: 'Former smoker, 20 pack-year history, quit 1 year ago',
        },
    ],
};

const patientProfileJavier = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-javier.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Javier",
    diagnosis: "Stage IIIA<sup>j</sup> and T3, N2, M0 nonsquamous NSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'Has undergone a surgical resection and received adjuvant platinum-based chemotherapy',
            emphasize: false,
        },
        {
            key: 'ECOG PS',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: 'TPS <1%',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '73',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
        {
            key: 'Smoking Status',
            value: '61 pack-year history, currently reduced to 1 biweekly pack',
        },
    ],
};

const pageReferences = [
    {
        label: "1.",
        text: `O’Brien M, Paz-Ares L, Marreaud S, et al; for the PEARLS/KEYNOTE&#8288;-&#8288;091 investigators. Pembrolizumab versus placebo as adjuvant therapy for completely resected stage IB-IIIA non-small-cell lung cancer (PEARLS/KEYNOTE&#8288;-&#8288;091): an interim analysis of a randomised, triple-blind, phase 3 trial. <i>Lancet Oncol.</i> 2022;23(10):1274–1286. doi:10.1016/S1470-2045(22)00518-6`
    },
];

const appropriatePatientsFootnotes = [
    {
        label: "j.",
        text: "Per AJCC 7th ed."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYTRUDA Is the First and Only IO Indicated as Adjuvant Treatment Following Resection and Platinum-based Chemotherapy for Adult Patients With Stage IB (T2a&nbsp;≥4&nbsp;cm), II, or IIIA NSCLC, Regardless of PD&#8288;-&#8288;L1 Expression</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="In Patients With Stage IB (T2a&nbsp;≥4&nbsp;cm), II, or IIIA NSCLC, After Resection and Adjuvant Platinum-based Chemotherapy, Regardless of PD&#8288;-&#8288;L1 Expression">
                                <SecondaryCallout
                                    title="KEYTRUDA demonstrated a clinically meaningful improvement in disease-free survival (DFS) compared to placebo"
                                    alt=""
                                />
                                <KMCurve
                                    title="Kaplan-Meier Estimates of DFS in KEYNOTE&#8288;-&#8288;091 for Patients Who Received Adjuvant Chemotherapy"
                                    hr="HR=0.73 (95% CI, 0.60–0.89)<sup>a</sup>"
                                    image={() => <StaticImage src="../../assets/nsclc-adjuvant-KM-DFS091-v7.png" 
                                    placeholder="blurred" 
                                    alt="Kaplan-Meier Estimates of Disease-Free Survival (DFS) in KEYNOTE-091" />}
                                    additionalInformation={[{
                                        text: `<ul>
                                            <li>In an exploratory subgroup analysis of the 167 patients (14%) who did not receive adjuvant chemotherapy, the DFS HR was 1.25 (95% CI, 0.76–2.05).</li>
                                            <li>At the time of the interim analysis, OS results were not mature (42% with events in the overall population).</li>
                                        </ul>`
                                    }]}
                                    footnotes={kmFootnotes}
                                    definitions="CI = confidence interval; HR = hazard ratio; IO = immunotherapy; OS = overall survival; T = tumor."
                                />
                                <FeaturedStat
                                    statistic="27%"
                                    title="Reduction in the risk of disease recurrence or death with KEYTRUDA compared to placebo<div style='margin-top: 10px;'><b>HR=0.73 (95% CI, 0.60–0.89)<sup>b</sup></b></div>"
                                    footnotes={featuredStatFootnotes}
                                />
                                <ComparisonTable
                                    title="Disease-Free Survival (DFS)"
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA (n=506)',
                                                },
                                                {
                                                    legendTitle: 'Placebo (n=504)',
                                                },
                                            ],
                                            [
                                                {
                                                    colspan: 2,
                                                    centerText: true,
                                                    hrCiP: 'HR=0.73 (95% CI, 0.60–0.89)<sup>c</sup>'
                                                }
                                            ],
                                            [
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '177',
                                                    dataSubPoint: '(35%)',
                                                },
                                                {
                                                    label: 'Events observed',
                                                    dataPoint: '231',
                                                    dataSubPoint: '(46%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median DFS',
                                                    dataPoint: '58.7 months',
                                                    dataSubPoint: '(95% CI, 39.2 months–NR)',
                                                },
                                                {
                                                    label: 'Median DFS',
                                                    dataPoint: '34.9 months',
                                                    dataSubPoint: '(95% CI, 28.6 months–NR)',
                                                },
                                            ],
                                        ]
                                    }
                                    definitions="NR = not reached."
                                    footnotes={comparisonTableFootnotes}
                                />
                                <SecondaryCallout
                                    title="Median DFS for patients who received adjuvant chemotherapy was 58.7 months (~5 years<sup>d</sup>) for KEYTRUDA vs 34.9 months (~3 years<sup>e</sup>) for placebo"
                                    footnotes={secondaryCalloutFootnotes}
                                    alt=""
                                />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <NSCLC_ADJUVANT />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <PageSubSection title="Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;091">
                                <PatientProfile {...patientProfileSandra} />
                                <PatientProfile {...patientProfileKevin} />
                                <PatientProfile {...patientProfileJavier} />
                                <ReferencesBlock references={appropriatePatientsFootnotes} />
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
